import apiClient from '@/_shared/services/apiClient';
import TransferDetails from '@/client_profile/types/TransferDetails';

export const transferClient = async (transferDetails: TransferDetails, clientId: number) => {
  try {
    const url = `/api/v2/clients/${clientId}/transfer`;
    const data = { transfer_details: transferDetails };
    return apiClient.put(url, data);
  } catch (error) {
    return error;
  }
};

export const getMissingClientServices = async (transferDetails: TransferDetails) => {
  try {
    const url = `/api/v2/clients/${transferDetails.client_id}/careplan/missing_services`;
    const params = {
      dest_org_unit_id: transferDetails.to,
      source_org_unit_id: transferDetails.from,
    };
    return apiClient.get(url, { params });
  } catch (error) {
    return error;
  }
};
